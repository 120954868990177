import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import MapDashboardLayout from '@/views/Layout/MapDashboardLayout.vue';
import LandingPageLayout from '@/views/Layout/LandingPageLayout.vue';
import LandingPageAdmin from '@/views/Layout/LandingPageAdmin.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Pages
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');

const ForgetPassword = () => import("@/views/Pages/ForgetPassword.vue");
const ResetPassword = () => import("@/views/Pages/ResetPassword.vue");

// const Milestone = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Milestone/MilestoneListing.vue');
// const MilestoneNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Milestone/MilestoneNew.vue');
// const MilestoneEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Milestone/MilestoneEdit.vue');

//const ProjectDashboardListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Project/ProjectDashboardListing.vue');


const SiteDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Project/Site/SiteDashboard.vue');
const SiteNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Project/Site/SiteNew.vue');
const SiteEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Project/Site/SiteEdit.vue');

// Template
const MilestoneTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateListing.vue');
const MilestoneTemplateNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateNew.vue');
const MilestoneTemplateEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateEdit.vue');

const MilestoneTemplateDetailListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateDetailListing.vue');
const MilestoneTemplateDetailNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateDetailNew.vue');
const MilestoneTemplateDetailEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Milestone/MilestoneTemplateDetailEdit.vue');

const DocumentTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateListing.vue');
const DocumentTemplateNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateNew.vue');
const DocumentTemplateEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateEdit.vue');

const DocumentTemplateDetailListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateDetailListing.vue');
const DocumentTemplateDetailNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateDetailNew.vue');
const DocumentTemplateDetailEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Document/DocumentTemplateDetailEdit.vue');

const TaskTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateListing.vue');
const TaskTemplateNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateNew.vue');
const TaskTemplateEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateEdit.vue');

const TaskTemplateDetailListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateDetailListing.vue');
const TaskTemplateDetailNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateDetailNew.vue');
const TaskTemplateDetailEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Task/TaskTemplateDetailEdit.vue');

const ProjectDocumentTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateListing.vue');
const ProjectDocumentTemplateNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateNew.vue');
const ProjectDocumentTemplateEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateEdit.vue');

const ProjectTaskTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateListing.vue');
const ProjectTaskTemplateNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateNew.vue');
const ProjectTaskTemplateEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateEdit.vue');

const ProjectDocumentTemplateDetailListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateDetailListing.vue');
const ProjectDocumentTemplateDetailNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateDetailNew.vue');
const ProjectDocumentTemplateDetailEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectDocument/ProjectDocumentTemplateDetailEdit.vue');

const ProjectTaskTemplateDetailListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateDetailListing.vue');
const ProjectTaskTemplateDetailNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateDetailNew.vue');
const ProjectTaskTemplateDetailEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/ProjectTask/ProjectTaskTemplateDetailEdit.vue');

const ProjectSiteTemplateViewForm = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue');
// Developer
const DeveloperListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Developer/DeveloperListing.vue');
const DeveloperNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Developer/DeveloperNew.vue');
const DeveloperEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Developer/DeveloperEdit.vue');

// Group of Project Engineers
const ProjectEngineerGroupListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/GroupOfEngineers/ProjectEngineerGroupListing.vue');
const ProjectEngineerGroupNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/GroupOfEngineers/ProjectEngineerGroupNew.vue');
const ProjectEngineerGroupEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/GroupOfEngineers/ProjectEngineerGroupEdit.vue');

// Module Brand
const ModuleBrandListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ModuleBrand/ModuleBrandListing.vue');
const ModuleBrandNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ModuleBrand/ModuleBrandNew.vue');
const ModuleBrandEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ModuleBrand/ModuleBrandEdit.vue');

// Developer
const ContractorListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Contractor/ContractorListing.vue');
const ContractorNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Contractor/ContractorNew.vue');
const ContractorEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Contractor/ContractorEdit.vue');

// Project Type
const ProjectTypeListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectType/ProjectTypeListing.vue');
const ProjectTypeNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectType/ProjectTypeNew.vue');
const ProjectTypeEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectType/ProjectTypeEdit.vue');

// Project Category
const ProjectCategoryListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectCategory/ProjectCategoryListing.vue');
const ProjectCategoryNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectCategory/ProjectCategoryNew.vue');
const ProjectCategoryEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/ProjectCategory/ProjectCategoryEdit.vue');

// Safety Observation Category
const SafetyObservationCategoryListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationCategory/SafetyObservationCategoryListing.vue');
const SafetyObservationCategoryNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationCategory/SafetyObservationCategoryNew.vue');
const SafetyObservationCategoryEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationCategory/SafetyObservationCategoryEdit.vue');

// Safety Observation Priority
const SafetyObservationPriorityListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationPriority/SafetyObservationPriorityListing.vue');
const SafetyObservationPriorityNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationPriority/SafetyObservationPriorityNew.vue');
const SafetyObservationPriorityEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/SafetyObservationPriority/SafetyObservationPriorityEdit.vue');

// PRM
const ImportPRMRecords = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectRequirementManagement/ImportPRMRecords.vue');
const CreateProjectsFromPrm = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectRequirementManagement/CreateProjectsFromPrm.vue');

// Project
const ProjectListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListing.vue');
const ProjectListingCNI = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListingCNI.vue');
const ProjectListingSN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListingSN.vue');
const ProjectListingSN2 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListingSN2.vue');
const ProjectListingSN3= () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListingSN3.vue');
const ProjectListingSN5 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectListingSN5.vue');
const ProjectDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectDashboard.vue');
const ProjectDashboardV2 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectDashboardV2.vue');
const ProjectDashboardV3 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectDashboardV3.vue');
const OpportunityListingOpen = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/OpportunityListingOpen.vue');
const OpportunityListingWon = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/OpportunityListingWon.vue');
const OpportunityListingLost = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/OpportunityListingLost.vue');
const OpportunityListingArchived = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/OpportunityListingArchived.vue');
const OpportunityDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/OpportunityDashboard.vue');
const ProjectNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectNew.vue');
const ProjectEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectEdit.vue');
const SiteDocumentListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectSiteDocumentListingAll.vue');
const SiteTaskListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectSiteTaskListingAll.vue');
const ProjectImportProjectSite = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Project/ProjectImportProjectSite.vue');


// Group
const ProjectGroupDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupDashboard.vue');
const ProjectGroupListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupListing.vue');
const ProjectGroupNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupNew.vue');
const ProjectGroupNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupNewFrProject.vue');
const ProjectGroupEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupEdit.vue');
const ProjectGroupDocumentListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupDocumentListingAll.vue');
const ProjectGroupTaskListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectGroup/ProjectGroupTaskListingAll.vue');

// Site
const ProjectSiteDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDashboard.vue');
const ProjectSiteDashboardV2 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDashboardV2.vue');
const ProjectSiteDashboardSN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDashboardSN.vue');
const ProjectSiteDashboardUtilities = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDashboardUtilities.vue');
const ProjectSiteDashboardContractor = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDashboardContractor.vue');
const ServiceLocationDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ServiceLocationDashboard.vue');
const ProjectSiteListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListing.vue');
const ProjectSiteListingByGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByGroup.vue');
const ProjectSiteListingContractor = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingContractor.vue');
const ProjectSiteListingFilter = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingFilter.vue');
const ProjectSiteHistoricalUpdates = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteHistoricalUpdates.vue');
const ProjectSiteUtilitiesDashboardData = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteUtilitiesDashboardData.vue');
const ProjectSiteUtilitiesDashboardDataNew  = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteUtilitiesDashboardDataNew.vue');
const ProjectSiteUtilitiesDashboardDataEdit  = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteUtilitiesDashboardDataEdit.vue');

const ProjectSiteListingByProjectTurnOn = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectTurnOn.vue');
const ProjectSiteListingByProjectMonthlyTurnOn = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectMonthlyTurnOn.vue');
const ProjectSiteListingByProjectMonthlyTargetTurnOn = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectMonthlyTargetTurnOn.vue');
const ProjectSiteListingByProjectInProgress = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectInProgress.vue');
const ProjectSiteListingByProjectGroupTurnOn = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectGroupTurnOn.vue');
const ProjectSiteListingByProjectGroupInProgress = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingByProjectGroupInProgress.vue');

const ProjectSiteListingOngoingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingAll.vue');
const ProjectSiteListingOngoingCNI = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingCNI.vue');
const ProjectSiteListingOngoingSN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingSN.vue');
//  const ProjectSiteListingOngoingUtilities = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingSN.vue');
// const ProjectSiteListingFromOngoingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingFromOngoingAll.vue');
const ProjectSiteListingOngoingStatusCNI = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingStatusCNI.vue');
const ProjectSiteListingOngoingStatusSN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingOngoingStatusSN.vue');
const ProjectSiteListingTurnOnMonthlyCNI = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingTurnOnMonthlyCNI.vue');
const ProjectSiteListingTurnOnMonthlySN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteListingTurnOnMonthlySN.vue');


const ProjectSiteNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteNew.vue');
const ProjectSiteNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteNewFrProject.vue');
const ProjectSiteNewFrGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteNewFrGroup.vue');
const ProjectSiteEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteEdit.vue');
const ProjectSiteEditSN = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteEditSN.vue');
const ProjectSiteDocumentListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteDocumentListingAll.vue');
const ProjectSiteTaskListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectSite/ProjectSiteTaskListingAll.vue');

// Site Contractor
const SiteContractorListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteContractor/SiteContractorListing.vue');
const SiteContractorNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteContractor/SiteContractorNew.vue');

// Project Milestone
const ProjectMilestoneListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMilestone/ProjectMilestoneListing.vue');
const ProjectMilestoneNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMilestone/ProjectMilestoneNew.vue');
const ProjectMilestoneEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMilestone/ProjectMilestoneEdit.vue');

// Task
const TaskListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskListing.vue');
const TaskNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskNew.vue');
const TaskNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskNewFrProject.vue');
const TaskNewFrGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskNewFrGroup.vue');
const TaskNewFrSite = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskNewFrSite.vue');
const TaskEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskEdit.vue');
const TaskEditContractor = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Task/TaskEditContractor.vue');

const ProjectTaskNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectTask/ProjectTaskNewFrProject.vue');
const ProjectTaskEditFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectTask/ProjectTaskEditFrProject.vue');
const ProjectTaskListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectTask/ProjectTaskListingAll.vue');
//ProjectTaskEditFrProject.vue
// Import
const ImportNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Import/ImportNew.vue');
const ImportNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Import/ImportNewFrProject.vue');
const ImportNewFrGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Import/ImportNewFrGroup.vue');
const ImportNewFrSite = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Import/ImportNewFrSite.vue');

// Document
const DocumentListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentListing.vue');
const DocumentNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentNew.vue');
const DocumentNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentNewFrProject.vue');
const DocumentNewFrGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentNewFrGroup.vue');
const DocumentNewFrSite = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentNewFrSite.vue');
const DocumentEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Document/DocumentEdit.vue');
const DocumentDetailsListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentDetails/DocumentDetailsListing.vue');
const DocumentDetailsNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentDetails/DocumentDetailsNew.vue');

const DocumentCommentListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentComment/DocumentCommentListing.vue');
const DocumentCommentNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentComment/DocumentCommentNew.vue');
const DocumentCommentEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentComment/DocumentCommentEdit.vue');

const ProjectDocumentNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentNewFrProject.vue');
const ProjectDocumentEditFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentEditFrProject.vue');
const ProjectDocumentDetailsListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentDetailsListing.vue');
const ProjectDocumentApprovalStatus = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentApprovalStatus.vue');
const ProjectDocumentRecurringListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentRecurringListing.vue');
//ProjectDocumentEditFrProject  ProjectDocumentRecurringListing SiteDocumentListing SiteDocumentNew SiteDocumentRecurringListing
const ProjectDocumentListingAll = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectDocument/ProjectDocumentListingAll.vue');

const SiteDocumentListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentListing.vue');
const SiteDocumentNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentNew.vue');
const SiteDocumentEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentEdit.vue');
const SiteDocumentDetailsListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentDetailsListing.vue');
const SiteDocumentRecurringListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentRecurringListing.vue');
const SiteDocumentApprovalStatus = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentApprovalStatus.vue');
const SiteDocumentNewFrProject = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentNewFrProject.vue');
const SiteDocumentNewFrGroup = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentNewFrGroup.vue');
const SiteDocumentNewFrSite = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/SiteDocument/SiteDocumentNewFrSite.vue');

// siteDocumentApprovalStatus SiteDocumentNewFrProject SiteDocumentNewFrGroup
//const ConsolidatedDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ConsolidatedDashboard/ConsolidatedDashboard.vue');
const ExperimentalItem1 = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/ExperimentalItem1.vue');
const ExperimentUploadPhotos = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/ExperimentUploadPhotos.vue');
const ConsolidatedDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/ConsolidatedDashboard.vue');
const OverviewDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ConsolidatedDashboard/OverviewDashboard.vue');
const ProductionReport = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/ProductionReport.vue');
const CycleTimeReport = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/CycleTimeReport.vue');
const MapDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/MapDashboard.vue');

//New part related with PMN design

const MainLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/PMN/LandingPage/MainLandingPage.vue');
const ContractorLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/PMN/LandingPage/ContractorLandingPage.vue');
const ContractorProjectLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Contractor/index.vue');
const ProjectLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/index.vue');
const UtilitiesPM = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Utilities/index.vue');
const UtilitiesPMDetail = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Utilities/Detail.vue');
const UtilitiesPMEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Utilities/Edit.vue');
const UtilitiesSiteEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Utilities/EditSite.vue');
//const UtilitiesHistory = () => import(/* webpackChunkName: "pages" */ '@/views/ProjectMgmt/Utilities/ProjectSiteHistoricalUpdates.vue');
const UtilitiesHistory = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Utilities/HistoryProject.vue');
//const DashboardSiteDetail = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Dashboard/MainDashboard/DetailSite.vue');
const ContractorSiteMilestonePage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Contractor/milestone_task.vue');



const PlannerLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Planner/index.vue');
const ProjectPlannerLandingPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Planner/index.vue');
const PlannerProjectPage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Planner/project.vue');
const PlannerSitePage = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Planner/site_milestone.vue');


const AdminSetupTracking = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/index.vue');
const AdminAddSetupTracking = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Add.vue');
const MapSesna = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/MapSesna.vue');

const ProjectDocumentDetail = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/DetailDoc.vue');
const ContractorDocumentDetail = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Contractor/DetailDoc.vue');
const ReviewerDocumentDetail = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Reviewer/DetailDoc.vue');
const ReviewerNewDocument = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Reviewer/New.vue');
const ReviewerDocumentHistory = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/Reviewer/DetailDocHistory.vue');

const CNISiteList = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/CNI/index.vue');

const CNISiteMilestone = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/CNI/site_milestone.vue');

// const CNIProjectGrid = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/CNI/GridProject.vue');
// const CNISiteGrid = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/ProjectMgmt/CNI/GridSite.vue');


// Audit Log
const AuditLogDocumentListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogDocument/AuditLogDocumentListing.vue');
const AuditLogProjectListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogProject/AuditLogProjectListing.vue');
const AuditLogSetupListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogSetup/AuditLogSetupListing.vue');
const AuditLogTaskListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogTask/AuditLogTaskListing.vue');
const AuditLogTemplateListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogTemplate/AuditLogTemplateListing.vue');
const AuditLogSafetyInspectionListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/AuditLog/AuditLogSafetyInspection/AuditLogSafetyInspectionListing.vue');

// User
const UserListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/User/UserListing.vue');
const UserNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/User/UserNew.vue');
const UserEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/User/UserEdit.vue');

const Profile = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/MyUserProfile.vue');

// SAMPLE
const DocumentDetailsRecurringListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentDetails/DocumentDetailsRecurringListing.vue');
const DocumentApprovalStatus = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocumentDetails/DocumentApprovalStatus.vue');

// Role
const RoleListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Role/RoleListing.vue');
const RoleAccessListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Role/RoleAccessListing.vue');
//const UserNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/User/UserNew.vue');
//const UserEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/User/UserEdit.vue');

// Health & Safety
const SafetyObservationListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationListing.vue');
const SafetyObservationNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationNew.vue');
const SafetyObservationEdit = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationEdit.vue');
const SafetyObservationView = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationView.vue');
const SafetyObservationItemCorrection = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationItemCorrection.vue');
const SafetyObservationItemView = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationItemView.vue');
const SafetyObservationReport = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationReport.vue');
const SafetyObservationItemOpenPendingListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/SafetyObservationItemOpenPendingListing.vue');

// ToolboxMeetingListing
const ToolboxMeetingListing = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/ToolboxMeetingListing.vue');
const ToolboxMeetingNew = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/ToolboxMeetingNew.vue');
const ToolboxMeetingView = () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/HealthSafety/ToolboxMeetingView.vue');

let templatesMenu = {
    path: '/templates',
    component: DashboardLayout,
    name: 'Template',
    children: [
        {
            path: 'projectTaskTemplateListing',
            name: 'Project Task Template Listing',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateListing
        },
        {
            path: 'projectTaskTemplateNew',
            name: 'New Project Task Template',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateNew
        },
        {
            path: 'projectTaskTemplateEdit/:id',
            name: 'Edit Project Task Template',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateEdit
        },

        {
            path: 'projectDocumentTemplateListing',
            name: 'Project Document Template Listing',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateListing
        },
        {
            path: 'projectDocumentTemplateNew',
            name: 'New Project Document Template',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateNew
        },
        {
            path: 'projectDocumentTemplateEdit/:id',
            name: 'Edit Project Document Template',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateEdit
        },

        {
            path: 'projectTaskTemplateDetailListing/:id',
            name: 'Project Task Template Detail Listing',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateDetailListing
        },
        {
            path: 'projectTaskTemplateDetailNew/:id',
            name: 'New Project Task Template Detail',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateDetailNew
        },
        {
            path: 'projectTaskTemplateDetailEdit/:id',
            name: 'Edit Project Task Template Detail',
            meta : { requiresAuth: true },
            component: ProjectTaskTemplateDetailEdit
        },

        {
            path: 'projectDocumentTemplateDetailListing/:id',
            name: 'Project Document Template Detail Listing',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateDetailListing
        },
        {
            path: 'projectDocumentTemplateDetailNew/:id',
            name: 'New Project Document Template Detail',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateDetailNew
        },
        {
            path: 'projectDocumentTemplateDetailEdit/:id',
            name: 'Edit Project Document Template Detail',
            meta : { requiresAuth: true },
            component: ProjectDocumentTemplateDetailEdit
        },

        {
            path: 'milestoneTemplateListing',
            name: 'Milestone Template Listing',
            meta : { requiresAuth: true },
            component: MilestoneTemplateListing
        },
        {
            path: 'milestoneTemplateNew',
            name: 'Milestone Template New',
            meta : { requiresAuth: true },
            component: MilestoneTemplateNew
        },
        {
            path: 'milestoneTemplateEdit/:id',
            name: 'Milestone Template Edit',
            meta : { requiresAuth: true },
            component: MilestoneTemplateEdit
        },
        {
            path: 'milestoneTemplateDetailListing/:id',
            name: 'Milestone Template Detail Listing',
            meta : { requiresAuth: true },
            component: MilestoneTemplateDetailListing
        },
        {
            path: 'milestoneTemplateDetailNew/:id',
            name: 'New Milestone Template Detail',
            meta : { requiresAuth: true },
            component: MilestoneTemplateDetailNew
        },
        {
            path: 'milestoneTemplateDetailEdit/:id',
            name: 'Edit Milestone Template Detail',
            meta : { requiresAuth: true },
            component: MilestoneTemplateDetailEdit
        },

        {
            path: 'documentTemplateListing',
            name: 'Document Template Listing',
            meta : { requiresAuth: true },
            component: DocumentTemplateListing
        },
        {
            path: 'documentTemplateNew',
            name: 'Document Template New',
            meta : { requiresAuth: true },
            component: DocumentTemplateNew
        },
        {
            path: 'documentTemplateEdit/:id',
            name: 'Document Template Edit',
            meta : { requiresAuth: true },
            component: DocumentTemplateEdit
        },

        {
            path: 'documentTemplateDetailListing/:id',
            name: 'Document Template Detail Listing',
            meta : { requiresAuth: true },
            component: DocumentTemplateDetailListing
        },
        {
            path: 'DocumentTemplateDetailNew/:id',
            name: 'New Document Template Detail',
            meta : { requiresAuth: true },
            component: DocumentTemplateDetailNew
        },
        {
            path: 'DocumentTemplateDetailEdit/:id',
            name: 'Edit Document Template Detail',
            meta : { requiresAuth: true },
            component: DocumentTemplateDetailEdit
        },

        {
            path: 'taskTemplateListing',
            name: 'Task Template Listing',
            meta : { requiresAuth: true },
            component: TaskTemplateListing
        },
        {
            path: 'taskTemplateNew',
            name: 'Task Template New',
            meta : { requiresAuth: true },
            component: TaskTemplateNew
        },
        {
            path: 'taskTemplateEdit/:id',
            name: 'Task Template Edit',
            meta : { requiresAuth: true },
            component: TaskTemplateEdit
        },

        {
            path: 'taskTemplateDetailListing/:id',
            name: 'Task Template Detail Listing',
            meta : { requiresAuth: true },
            component: TaskTemplateDetailListing
        },
        {
            path: 'taskTemplateDetailNew/:id',
            name: 'New Task Template Detail',
            meta : { requiresAuth: true },
            component: TaskTemplateDetailNew
        },
        {
            path: 'taskTemplateDetailEdit/:id',
            name: 'Edit Task Template Detail',
            meta : { requiresAuth: true },
            component: TaskTemplateDetailEdit
        },
        {
            path: 'ProjectSiteTemplateViewForm/:id',
            name: 'Project Site Template Detail',
            meta: {requiresAuth:true},
            component: ProjectSiteTemplateViewForm,
        }
    ]
};

let AdminMenu = {
    path: '/administrator',
    component: LandingPageAdmin,
    redirect: '/administrator/setup/tracking',
    name: 'Admin Menu',
    children: [
        // Document Repository - Category
        {
            path: 'setup/View/:tid/Category/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Category/Listing.vue'),
        },
        {
            path: 'setup/View/:tid/Category/New/:level',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Category/New.vue'),
        },
        {
            path: 'setup/View/:tid/Category/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Category/Edit.vue'),
        },
        // Document Repository - Template Document
        {
            path: 'setup/View/:tid/Document/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Document/Listing.vue'),
        },
        {
            path: 'setup/View/:tid/Document/New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Document/New.vue'),
        },
        {
            path: 'setup/View/:tid/Document/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Document/Edit.vue'),
        },
        // Document Repository - Template
        {
            path: 'setup/tracking',
            name: 'Document Tracking Template',
            component: AdminSetupTracking
        },
        {
            path: 'setup/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Edit.vue'),
        },
        {
            path: 'setup/New',
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/New.vue'),
        },
        {
            path: 'setup/Add',
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Admin/Setup/TrackingTemplate/Add.vue'),
        },
        {
            path: 'setup/tracking',
            name: 'Set Up',
            component: AdminSetupTracking
        },
    ]
}

let prmMenu = {
    path: '/prm',
    component: DashboardLayout,
    name: 'PRM',
    children: [
        {
            path: 'importPRMRecords',
            name: 'Import PRM Records',
            meta : { requiresAuth: true },
            component: ImportPRMRecords
        },
        {
            path: 'createProjectsFromPrm',
            name: 'Create Projects',
            meta : { requiresAuth: true },
            component: CreateProjectsFromPrm
        },
    ]
};

let setupsMenu = {
    path: '/setups',
    component: DashboardLayout,
    redirect: '/setups/projectType',
    name: 'Setup',
    children: [
        {
            path: 'developerListing',
            name: 'Developer Listing',
            meta : { requiresAuth: true },
            component: DeveloperListing
        },
        {
            path: 'developerNew',
            name: 'New Developer',
            meta : { requiresAuth: true },
            component: DeveloperNew
        },
        {
            path: 'developerEdit/:id',
            name: 'Edit Developer',
            meta : { requiresAuth: true },
            component: DeveloperEdit
        },
        {
            path: 'projectEngineerGroupListing',
            name: 'Group of Project Engineers',
            meta : { requiresAuth: true },
            component: ProjectEngineerGroupListing
        },
        {
            path: 'projectEngineerGroupNew',
            name: 'New Group of Project Engineers',
            meta : { requiresAuth: true },
            component: ProjectEngineerGroupNew
        },
        {
            path: 'projectEngineerGroupEdit/:id',
            name: 'Edit Group of Project Engineers',
            meta : { requiresAuth: true },
            component: ProjectEngineerGroupEdit
        },
        {
            path: 'moduleBrandListing',
            name: 'Module Brand Listing',
            meta : { requiresAuth: true },
            component: ModuleBrandListing
        },
        {
            path: 'moduleBrandNew',
            name: 'New Module Brand',
            meta : { requiresAuth: true },
            component: ModuleBrandNew
        },
        {
            path: 'moduleBrandEdit/:id',
            name: 'Edit Module Brand',
            meta : { requiresAuth: true },
            component: ModuleBrandEdit
        },

        {
            path: 'contractorListing',
            name: 'Contractor Listing',
            meta : { requiresAuth: true },
            component: ContractorListing
        },
        {
            path: 'contractorNew',
            name: 'New Contractor',
            meta : { requiresAuth: true },
            component: ContractorNew
        },
        {
            path: 'contractorEdit/:id',
            name: 'Edit Contractor',
            meta : { requiresAuth: true },
            component: ContractorEdit
        },

        {
            path: 'projectTypeListing',
            name: 'Project Type Listing',
            meta : { requiresAuth: true },
            component: ProjectTypeListing
        },
        {
            path: 'projectTypeNew',
            name: 'Project Type New',
            meta : { requiresAuth: true },
            component: ProjectTypeNew
        },
        {
            path: 'projectTypeEdit/:id',
            name: 'Project Type Edit',
            meta : { requiresAuth: true },
            component: ProjectTypeEdit
        },

        {
            path: 'projectCategoryListing',
            name: 'Project Category Listing',
            meta : { requiresAuth: true },
            component: ProjectCategoryListing
        },
        {
            path: 'projectCategoryNew',
            name: 'Project Category New',
            meta : { requiresAuth: true },
            component: ProjectCategoryNew
        },
        {
            path: 'projectCategoryEdit/:id',
            name: 'Project Category Edit',
            meta : { requiresAuth: true },
            component: ProjectCategoryEdit
        },
        {
            path: 'safetyObservationCategoryListing',
            name: 'Safety Observation Category Listing',
            meta : { requiresAuth: true },
            component: SafetyObservationCategoryListing
        },
        {
            path: 'safetyObservationCategoryNew',
            name: 'Safety Observation Category New',
            meta : { requiresAuth: true },
            component: SafetyObservationCategoryNew
        },
        {
            path: 'safetyObservationCategoryEdit/:id',
            name: 'Safety Observation Category Edit',
            meta : { requiresAuth: true },
            component: SafetyObservationCategoryEdit
        },
        {
            path: 'safetyObservationPriorityListing',
            name: 'Safety Observation Priority Listing',
            meta : { requiresAuth: true },
            component: SafetyObservationPriorityListing
        },
        {
            path: 'safetyObservationPriorityNew',
            name: 'Safety Observation Priority New',
            meta : { requiresAuth: true },
            component: SafetyObservationPriorityNew
        },
        {
            path: 'safetyObservationPriorityEdit/:id',
            name: 'Safety Observation Priority Edit',
            meta : { requiresAuth: true },
            component: SafetyObservationPriorityEdit
        },
        {
            path: 'UserListing',
            name: 'User Listing',
            meta : { requiresAuth: true },
            component: UserListing
        },
        {
            path: 'UserNew',
            name: 'User New',
            meta : { requiresAuth: true },
            component: UserNew
        },
        {
            path: 'UserEdit/:id',
            name: 'User Edit',
            meta : { requiresAuth: true },
            component: UserEdit
        },
        {
            path: 'RoleListing',
            name: 'Role Listing',
            meta : { requiresAuth: true },
            component: RoleListing
        },
        {
            path: 'RoleAccessListing',
            name: 'Role Access Listing',
            meta : { requiresAuth: true },
            component: RoleAccessListing
        },

        // Document Repository - Template
        {
            path: 'DocRepo/Template/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Template/Listing.vue'),
        },
        {
            path: 'DocRepo/Template/New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Template/New.vue'),
        },
        {
            path: 'DocRepo/Template/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Template/Edit.vue'),
        },
        // Document Repository - Category
        {
            path: 'DocRepo/Template/View/:tid/Category/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Category/Listing.vue'),
        },
        {
            path: 'DocRepo/Template/View/:tid/Category/New/:level',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Category/New.vue'),
        },
        {
            path: 'DocRepo/Template/View/:tid/Category/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Category/Edit.vue'),
        },
        // Document Repository - Template Document
        {
            path: 'DocRepo/Template/View/:tid/Document/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Document/Listing.vue'),
        },
        {
            path: 'DocRepo/Template/View/:tid/Document/New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Document/New.vue'),
        },
        {
            path: 'DocRepo/Template/View/:tid/Document/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/DocRepo/Document/Edit.vue'),
        },
        {
            path: 'Eform/Template/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Eform/Template/Listing.vue'),
        },
        {
            path: 'Eform/Template/New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Eform/Template/New.vue'),
        },
        {
            path: 'Eform/Template/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Eform/Template/Edit.vue'),
        },
        {
            path: 'Eform/Approver/Detail',
            meta: {requiredAuth:true},
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Setup/Eform/Approver/Detail.vue')
        }
    ]
};

let auditsMenu = {
    path: '/audits',
    component: DashboardLayout,
    // redirect: '/audits',
    name: 'Audit Logs',
    children:
        [
            {
                path: 'auditLogDocumentListing',
                name: 'Audit Log Document Listing',
                meta : { requiresAuth: true },
                component: AuditLogDocumentListing
            },
            {
                path: 'auditLogProjectListing',
                name: 'Audit Log Project Listing',
                meta : { requiresAuth: true },
                component: AuditLogProjectListing
            },
            {
                path: 'auditLogSetupListing',
                name: 'Audit Log Setup Listing',
                meta : { requiresAuth: true },
                component: AuditLogSetupListing
            },
            {
                path: 'auditLogTaskListing',
                name: 'Audit Log Task Listing',
                meta : { requiresAuth: true },
                component: AuditLogTaskListing
            },
            {
                path: 'auditLogTemplateListing',
                name: 'Audit Log Template Listing',
                meta : { requiresAuth: true },
                component: AuditLogTemplateListing
            },
            {
                path: 'auditLogSafetyInspectionListing',
                name: 'Audit Log Safety Inspection Listing',
                meta : { requiresAuth: true },
                component: AuditLogSafetyInspectionListing
            },
        ]
};

let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    name: 'Pages',
    children: [
        {
            path: 'profile',
            name: 'Profile Page',
            meta : { requiresAuth: true },
            component: Profile
        },
    ]
};

let eformsMenu = {
    path: '/eForms',
    component: DashboardLayout,
    name: 'Eforms',
    children : [
        {
            path: 'eFormListing',
            name: 'Eform Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Listing.vue'),
        },
        {
            path: 'eFormNew',
            name: 'Eform New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/New.vue'),
        },
        {
            path: 'Edit/:id',
            name: 'Eform Edit',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Edit.vue'),
        },
        {
            path: 'ReviewerListing/:type',
            name: 'EForm Reviewver',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Reviewer/Listing.vue'),
        },
        {
            path: 'ReviewerDetail/:type/:id',
            name: 'EForm Submission Detail',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Reviewer/Detail.vue'),
        },
        {
            path: 'SubmitterListing/:type',
            name: 'EForm Submission',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Submitter/Listing.vue'),
        },
        {
            path: 'Detail/:type/:id',
            name: 'EForm Submission Detail',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/Eforms/Submitter/Detail.vue'),
        }
    ]
};

let healthSafetysMenu = {
    path: '/healthSafety',
    component: DashboardLayout,
    name: 'Health & Safety',
    children: [
        {
            path: 'safetyObservationListing',
            name: 'Safety Inspection',
            meta : { requiresAuth: true },
            component: SafetyObservationListing
        },
        {
            path: 'safetyObservationNew',
            name: 'Safety Inspection New',
            meta : { requiresAuth: true },
            component: SafetyObservationNew
        },
        {
            path: 'safetyObservationEdit/:id',
            name: 'Safety Inspection Edit',
            meta : { requiresAuth: true },
            component: SafetyObservationEdit
        },
        {
            path: 'safetyObservationView/:id',
            name: 'Safety Inspection View',
            meta : { requiresAuth: true },
            component: SafetyObservationView
        },
        {
            path: 'safetyObservationItemCorrection/:id',
            name: 'Safety Observation Item Correction',
            meta : { requiresAuth: true },
            component: SafetyObservationItemCorrection
        },
        {
            path: 'safetyObservationItemView/:id',
            name: 'Safety Observation Item',
            meta : { requiresAuth: true },
            component: SafetyObservationItemView
        },
        {
            path: 'safetyObservationReport',
            name: 'Safety Inspection Report',
            meta : { requiresAuth: true },
            component: SafetyObservationReport
        },
        {
            path: 'safetyObservationItemOpenPendingListing',
            name: 'My Open Safety Observations',
            meta : { requiresAuth: true },
            component: SafetyObservationItemOpenPendingListing
        },
        {
            path: 'toolboxMeetingListing',
            name: 'Toolbox Meeting',
            meta : { requiresAuth: true },
            component: ToolboxMeetingListing
        },
        {
            path: 'toolboxMeetingNew',
            name: 'Toolbox Meeting New',
            meta : { requiresAuth: true },
            component: ToolboxMeetingNew
        },
        {
            path: 'toolboxMeetingView/:id',
            name: 'Toolbox Meeting View',
            meta : { requiresAuth: true },
            component: ToolboxMeetingView
        },
    ]
}

const docRepoRoutes = {
    path: '/DocRepo/P/:pid/S/:sid',
    component: DashboardLayout,
    children: [
        {
            path: 'Init',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Init.vue'),
        },
        {
            path: 'Home',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Home.vue'),
        },
        {
            path: 'D/:id/Revision',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Revision.vue'),
        },
        // Category
        {
            path: 'Category/Listing/:level',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Category/Listing.vue'),
        },
        {
            path: 'Category/New/:level',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Category/New.vue'),
        },
        {
            path: 'Category/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Category/Edit.vue'),
        },
        // Document
        {
            path: 'Document/Listing',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Document/Listing.vue'),
        },
        {
            path: 'Document/New',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Document/New.vue'),
        },
        {
            path: 'Document/Edit/:id',
            meta : { requiresAuth: true },
            component: () => import(/* webpackChunkName: "pages" */ '@/views/SemCorp/DocRepo/Document/Edit.vue'),
        },
    ],
}

let authPages = {
    path: '/',
    base: process.env.VUE_APP_HOME_LINK,
    component: AuthLayout,
    name: 'Authentication',
    children: [
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                noBodyBackground: true
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: "/forgot-password",
            name: 'Forget Password',
            component: ForgetPassword,
        },
        {
            path: "/reset-password/:token",
            name: 'Reset Password',
            component: ResetPassword,
        },
        {
            path: '/otp-verification/:tempToken',
            name: 'OTP Verfication',
            component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/OtpVerification.vue'),
        },
        { path: '*', component: NotFound }
    ]
};

const routes = [
    {
        path: '/',
        redirect: '/login',
        name: 'Login'
    },
    pagesMenu,
    templatesMenu,
    prmMenu,
    setupsMenu,
    auditsMenu,
    healthSafetysMenu,
    eformsMenu,
    AdminMenu,
    docRepoRoutes,
    {
        path: '/',
        component: MapDashboardLayout,
        redirect: '#',
        name: 'iTask',
        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: 'mainDashboard',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: MapDashboard
            },
        ]
    },
    {
        path:'/',
        component: LandingPageLayout,
        redirect: '#',
        name: 'iTask',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'mainLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: MainLandingPage
            },
            {
                path: 'ContractorDocumentDetail/:id',
                name: 'ContractorDocumentDetail',
                meta : { requiresAuth: true },
                component: ContractorDocumentDetail
            },
            {
                path: 'ReviewerDocumentDetail/:id',
                name: 'ReviewerDocumentDetail',
                meta : { requiresAuth: true },
                component: ReviewerDocumentDetail
            },
            {
                path: ':id/New',
                name: 'ReviewerNewDocument',
                meta : { requiresAuth: true },
                component: ReviewerNewDocument,
                props: route => ({ id: route.params.id, proj_id: route.query.proj_id })

            },
            {
                path: ':id/DocumentHistory',
                name: 'ReviewerDocumentHistory',
                meta : { requiresAuth: true },
                component: ReviewerDocumentHistory,
                props: route => ({
                    id: route.params.id,
                    proj_id: route.query.proj_id,
                    site_id: route.query.site_id
                })
            },
            {
                path: 'CNISiteList',
                name: 'CNISiteList',
                meta : { requiresAuth: true },
                component: CNISiteList
            },
            {
                path: 'CNISiteMilestone',
                name: 'CNISiteList',
                meta : { requiresAuth: true },
                component: CNISiteMilestone
            },
            {
                path: 'ProjectDocumentDetail/:id',
                name: 'ProjectDocumentDetail',
                meta : { requiresAuth: true },
                component: ProjectDocumentDetail

            },
            // {
            //     path: 'DashboardSiteDetail/:id',
            //     name: 'DashboardSiteDetail',
            //     meta : { requiresAuth: true },
            //     component: DashboardSiteDetail
            // },
            {
                path: 'contractorLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: ContractorLandingPage
            },
            {
                path: 'mapSesmoforExternal',
                name: 'SesnaDashboard',
                meta : { requiresAuth: true },
                component: MapSesna,
            },
            {
                path: 'projectLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: ProjectLandingPage
            },
            {
                path: 'plannerLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: PlannerLandingPage
            },
            {
                path: 'projectPlannerLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: ProjectPlannerLandingPage
            },
            {
                path: 'plannerproject',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: PlannerProjectPage
            },
            {
                path: 'plannersite',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: PlannerSitePage
            },
            {
                path: 'mapSesmo',
                name: 'SesnaDashboard',
                meta : { requiresAuth: true },
                component: MapSesna,
            },
            {
                path: 'projectUtilitiesLandingPage',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: UtilitiesPM
            },
            {
                path: 'projectUtilitiesDashboard/:id',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: UtilitiesPMDetail
            },
            {
                path: 'projectUtilitiesEdit/:id',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: UtilitiesPMEdit
            },
            {
                path: 'siteUtilitiesEdit/:id',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: UtilitiesSiteEdit
            },
            {
                path: 'siteUtilitiesHistory/:id',
                name: 'Dashboard',
                meta : { requiresAuth: true },
                component: UtilitiesHistory
            },
            // {
            //     path: 'siteGrid',
            //     name: 'Dashboard',
            //     meta : { requiresAuth: true },
            //     component: CNISiteGrid
            // },
            // {
            //     path: 'projectGrid',
            //     name: 'Dashboard',
            //     meta : { requiresAuth: true },
            //     component: CNIProjectGrid
            // },
            {
                path: 'contractorprojectlist',
                name: 'contractorprojectlist',
                meta: { requiresAuth: true },
                component: ContractorProjectLandingPage
            },
            {
                path: 'contractormilestone/:id',
                name: 'contractormilestone',
                meta: { requiresAuth: true },
                component: ContractorSiteMilestonePage
            },
        ]

    },
    {
        path: '/',
        component: DashboardLayout,
        redirect: '#',
        name: 'iTask',
        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: 'documentApprovalStatus/:id',
                name: 'Document Approval Status',
                meta : { requiresAuth: true },
                component: DocumentApprovalStatus
            },
            {
                path: 'documentDetailsRecurringListing/:id',
                name: 'Document Recurring Listing',
                meta : { requiresAuth: true },
                component: DocumentDetailsRecurringListing
            },
            {
                path: 'experimentalItem1',
                name: 'Experimental Item 1',
                meta : { requiresAuth: true },
                component: ExperimentalItem1
            },
            {
                path: 'experimentUploadPhotos',
                name: 'Experiment Upload Photos',
                meta : { requiresAuth: true },
                component: ExperimentUploadPhotos
            },
            {
                path: 'consolidatedDashboard',
                name: 'Consolidated Dashboard',
                meta : { requiresAuth: true },
                component: ConsolidatedDashboard
            },
            // {
            //   path: 'mapDashboard',
            //   name: 'Map Dashboard',
            //   meta : { requiresAuth: true },
            //   component: MapDashboard
            // },
            {
                path: 'productionReport',
                name: 'Production Report',
                meta : { requiresAuth: true },
                component: ProductionReport
            },
            {
                path: 'cycleTimeReport',
                name: 'Cycle Time Report',
                meta : { requiresAuth: true },
                component: CycleTimeReport
            },
            {
                path: 'overviewDashboard',
                name: 'Overview Dashboard',
                meta : { requiresAuth: true },
                component: OverviewDashboard
            },
            {
                path: 'siteDashboard',
                name: 'Site Dashboard',
                meta: {
                    navbarType: 'light',
                    requiresAuth: true,
                },
                component: SiteDashboard
            },
            {
                path: 'siteNew',
                name: 'New Site',
                meta : { requiresAuth: true },
                component: SiteNew
            },
            {
                path: 'siteEdit',
                name: 'Edit Site',
                meta : { requiresAuth: true },
                component: SiteEdit
            },
            {
                path: 'projectListing',
                name: 'Project Listing',
                meta : { requiresAuth: true },
                component: ProjectListing
            },
            {
                path: 'projectListingCNI',
                name: 'Project Listing CNI',
                meta : { requiresAuth: true },
                component: ProjectListingCNI
            },
            {
                path: 'projectListingSN',
                name: 'Project Listing SN',
                meta : { requiresAuth: true },
                component: ProjectListingSN
            },
            {
                path: 'projectListingSN2',
                name: 'Project Listing SN2',
                meta : { requiresAuth: true },
                component: ProjectListingSN2
            },
            {
                path: 'projectListingSN3',
                name: 'Project Listing SN3',
                meta : { requiresAuth: true },
                component: ProjectListingSN3
            },
            {
                path: 'projectListingSN5',
                name: 'Project Listing SN5',
                meta : { requiresAuth: true },
                component: ProjectListingSN5
            },
            {
                path: 'projectDashboard/:id',
                name: 'Project Dashboard',
                meta : { requiresAuth: true },
                component: ProjectDashboard
            },
            {
                path: 'projectDashboardV2/:id',
                name: 'Project Dashboard',
                meta : { requiresAuth: true },
                component: ProjectDashboardV2
            },
            {
                path: 'projectDashboardV3/:id',
                name: 'Project Dashboard',
                meta : { requiresAuth: true },
                component: ProjectDashboardV3
            },
            {
                path: 'opportunityListingOpen',
                name: 'Opportunity Listing Open',
                meta : { requiresAuth: true },
                component: OpportunityListingOpen
            },
            {
                path: 'opportunityListingWon',
                name: 'Opportunity Listing Won',
                meta : { requiresAuth: true },
                component: OpportunityListingWon
            },
            {
                path: 'opportunityListingLost',
                name: 'Opportunity Listing Lost',
                meta : { requiresAuth: true },
                component: OpportunityListingLost
            },
            {
                path: 'opportunityListingArchived',
                name: 'Opportunity Listing Archived',
                meta : { requiresAuth: true },
                component: OpportunityListingArchived
            },
            {
                path: 'opportunityDashboard/:id',
                name: 'Opportunity Dashboard',
                meta : { requiresAuth: true },
                component: OpportunityDashboard
            },
            {
                path: 'projectNew',
                name: 'New Project',
                meta : { requiresAuth: true },
                component: ProjectNew
            },
            {
                path: 'projectImportProjectSite/:id',
                name: 'Import Project Site',
                meta : { requiresAuth: true },
                component: ProjectImportProjectSite
            },
            {
                path: 'projectEdit/:id',
                name: 'Edit Project',
                meta : { requiresAuth: true },
                component: ProjectEdit
            },
            {
                path: 'siteDocumentListingAll/:id',
                name: 'All Site Document Listing',
                meta : { requiresAuth: true },
                component: SiteDocumentListingAll
            },
            {
                path: 'siteTaskListingAll/:id',
                name: 'All Site Task Listing',
                meta : { requiresAuth: true },
                component: SiteTaskListingAll
            },

            {
                path: 'projectGroupListing',
                name: 'Project Group Listing',
                meta : { requiresAuth: true },
                component: ProjectGroupListing
            },
            {
                path: 'projectGroupDashboard/:id',
                name: 'Project GroupDashboard',
                meta : { requiresAuth: true },
                component: ProjectGroupDashboard
            },
            {
                path: 'projectGroupNew',
                name: 'New Project Group',
                meta : { requiresAuth: true },
                component: ProjectGroupNew
            },
            {
                path: 'projectGroupNewFrProject/:id',
                name: 'New Project Group',
                meta : { requiresAuth: true },
                component: ProjectGroupNewFrProject
            },
            {
                path: 'projectGroupEdit/:id',
                name: 'Edit Project Group',
                meta : { requiresAuth: true },
                component: ProjectGroupEdit
            },
            {
                path: 'projectGroupDocumentListingAll/:id',
                name: 'All Site Document Listing',
                meta : { requiresAuth: true },
                component: ProjectGroupDocumentListingAll
            },
            {
                path: 'projectGroupTaskListingAll/:id',
                name: 'All Site Task Listing',
                meta : { requiresAuth: true },
                component: ProjectGroupTaskListingAll
            },

            {
                path: 'projectSiteListing',
                name: 'Project Site Listing',
                meta : { requiresAuth: true },
                component: ProjectSiteListing
            },

            {
                path: 'projectSiteListingByGroup/:id',
                name: 'Project Site Listing By Group',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByGroup
            },

            {
                path: 'projectSiteListingContractor',
                name: 'Project Site Listing for Contractors',
                meta : { requiresAuth: true },
                component: ProjectSiteListingContractor
            },

            {
                path: 'projectSiteListingFilter',
                name: 'Project Site Listing Filter',
                meta : { requiresAuth: true },
                component: ProjectSiteListingFilter
            },

            {
                path: 'projectSiteListingByProjectTurnOn/:id',
                name: 'Project Site Listing By Project Turn On',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectTurnOn
            },

            {
                path: 'projectSiteListingByProjectMonthlyTurnOn/:id/:id2/:id3',
                name: 'Project Site Listing By Project Monthly Turn On',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectMonthlyTurnOn
            },

            {
                path: 'projectSiteListingByProjectMonthlyTargetTurnOn/:id/:id2/:id3',
                name: 'Project Site Listing By Project Monthly Target Turn On',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectMonthlyTargetTurnOn
            },

            {
                path: 'projectSiteListingByProjectInProgress/:id',
                name: 'Project Site Listing By Project In Progress',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectInProgress
            },

            {
                path: 'projectSiteListingByProjectGroupTurnOn/:id',
                name: 'Project Site Listing By Project Group Turn On',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectGroupTurnOn
            },

            {
                path: 'projectSiteListingByProjectGroupInProgress/:id',
                name: 'Project Site Listing By Project Group In Progress',
                meta : { requiresAuth: true },
                component: ProjectSiteListingByProjectGroupInProgress
            },

            {
                path: 'projectSiteListingOngoingAll/:id',
                name: 'Project Site Listing Ongoing All',
                meta : { requiresAuth: true },
                component: ProjectSiteListingOngoingAll
            },

            {
                path: 'projectSiteListingOngoingCNI/:id',
                name: 'Project Site Listing Ongoing CNI',
                meta : { requiresAuth: true },
                component: ProjectSiteListingOngoingCNI
            },

            {
                path: 'projectSiteListingOngoingSN/:id',
                name: 'Project Site Listing Ongoing SN',
                meta : { requiresAuth: true },
                component: ProjectSiteListingOngoingSN
            },

            {
                path: 'projectSiteListingOngoingStatusCNI/:id/:id2',
                name: 'Project Site Listing Ongoing Status CNI',
                meta : { requiresAuth: true },
                component: ProjectSiteListingOngoingStatusCNI
            },

            {
                path: 'projectSiteListingOngoingStatusSN/:id/:id2',
                name: 'Project Site Listing Ongoing Status SN',
                meta : { requiresAuth: true },
                component: ProjectSiteListingOngoingStatusSN
            },

            {
                path: 'projectSiteListingTurnOnMonthlyCNI/:id/:id2/:id3',
                name: 'Project Site Listing Turn On Monthly CNI',
                meta : { requiresAuth: true },
                component: ProjectSiteListingTurnOnMonthlyCNI
            },

            {
                path: 'projectSiteListingTurnOnMonthlySN/:id/:id2/:id3',
                name: 'Project Site Listing Turn On Monthly SN',
                meta : { requiresAuth: true },
                component: ProjectSiteListingTurnOnMonthlySN
            },

            {
                path: 'projectSiteDashboard/:id',
                name: 'Project Site Dashboard',
                meta : { requiresAuth: true },
                component: ProjectSiteDashboard
            },
            {
                path: 'projectSiteDashboardV2/:id',
                name: 'Project Site Dashboard',
                meta : { requiresAuth: true },
                component: ProjectSiteDashboardV2
            },
            {
                path: 'projectSiteDashboardSN/:id',
                name: 'Project Site Dashboard SN',
                meta : { requiresAuth: true },
                component: ProjectSiteDashboardSN
            },
            {
                path: 'ProjectSiteDashboardUtilities/:id',
                name: 'Project Site Dashboard',
                meta : { requiresAuth: true },
                component: ProjectSiteDashboardUtilities
            },
            {
                path: 'ProjectSiteHistoricalUpdates/:id',
                name: 'Project File Historical Updates',
                meta : { requiresAuth: true },
                component: ProjectSiteHistoricalUpdates

            },

            {
                path: 'ProjectSiteUtilitiesDashboardData/:id',
                name: 'Project Site Utilities Data Manipulation',
                meta : { requiresAuth: true },
                component: ProjectSiteUtilitiesDashboardData
            },
            {
                path: 'ProjectSiteUtilitiesDashboardDataNew/:id',
                name: 'New Project Site Utilities Data Manipulation',
                meta : { requiresAuth: true },
                component: ProjectSiteUtilitiesDashboardDataNew

            },
            {
                path: 'ProjectSiteUtilitiesDashboardDataEdit/:id',
                name: 'Edit Project Site Utilities Data Manipulation',
                meta : { requiresAuth: true },
                component: ProjectSiteUtilitiesDashboardDataEdit

            },
            {
                path: 'projectSiteDashboardContractor/:id',
                name: 'Project Site Dashboard for Contractor',
                meta : { requiresAuth: true },
                component: ProjectSiteDashboardContractor
            },
            {
                path: 'serviceLocationDashboard/:id',
                name: 'Service Location Dashboard',
                meta : { requiresAuth: true },
                component: ServiceLocationDashboard
            },

            {
                path: 'projectSiteNew',
                name: 'New Project Site',
                meta : { requiresAuth: true },
                component: ProjectSiteNew
            },
            {
                path: 'projectSiteNewFrProject/:id',
                name: 'New Project Site',
                meta : { requiresAuth: true },
                component: ProjectSiteNewFrProject
            },
            {
                path: 'projectSiteNewFrGroup/:id',
                name: 'New Project Site',
                meta : { requiresAuth: true },
                component: ProjectSiteNewFrGroup
            },
            {
                path: 'projectSiteEdit/:id',
                name: 'Edit Project Site',
                meta : { requiresAuth: true },
                component: ProjectSiteEdit
            },
            {
                path: 'projectSiteEditSN/:id',
                name: 'Edit Project Site',
                meta : { requiresAuth: true },
                component: ProjectSiteEditSN
            },
            {
                path: 'projectSiteDocumentListingAll/:id',
                name: 'All Project Site Document Listing',
                meta : { requiresAuth: true },
                component: ProjectSiteDocumentListingAll
            },
            {
                path: 'projectSiteTaskListingAll/:id',
                name: 'All Project Site Task Listing',
                meta : { requiresAuth: true },
                component: ProjectSiteTaskListingAll
            },

            {
                path: 'projectMilestoneListing/:id',
                name: 'Project Milestone Listing',
                meta : { requiresAuth: true },
                component: ProjectMilestoneListing
            },
            {
                path: 'projectMilestoneNew/:id',
                name: 'New Project Milestone',
                meta : { requiresAuth: true },
                component: ProjectMilestoneNew
            },
            {
                path: 'projectMilestoneEdit/:id',
                name: 'Edit Project Milestone',
                meta : { requiresAuth: true },
                component: ProjectMilestoneEdit
            },

            {
                path: 'siteContractorListing/:id',
                name: 'Site Contractor Listing',
                meta : { requiresAuth: true },
                component: SiteContractorListing
            },

            {
                path: 'siteContractorNew/:id',
                name: 'New Site Contractor ',
                meta : { requiresAuth: true },
                component: SiteContractorNew
            },

            {
                path: 'taskListing',
                name: 'Task Listing',
                meta : { requiresAuth: true },
                component: TaskListing
            },
            {
                path: 'taskNew',
                name: 'New Site Task',
                meta : { requiresAuth: true },
                component: TaskNew
            },
            {
                path: 'taskNewFrProject/:id',
                name: 'New Task',
                meta : { requiresAuth: true },
                component: TaskNewFrProject
            },
            {
                path: 'projectTaskNewFrProject/:id',
                name: 'New Project Task ',
                meta : { requiresAuth: true },
                component: ProjectTaskNewFrProject
            },
            {
                path: 'projectTaskEditFrProject/:id',
                name: 'Edit Project Task ',
                meta : { requiresAuth: true },
                component: ProjectTaskEditFrProject
            },
            {
                path: 'projectTaskListingAll/:id',
                name: 'All Project Task Listing',
                meta : { requiresAuth: true },
                component: ProjectTaskListingAll
            },

            {
                path: 'taskNewFrGroup/:id',
                name: 'New Task',
                meta : { requiresAuth: true },
                component: TaskNewFrGroup
            },
            {
                path: 'taskNewFrSite/:id',
                name: 'New Task',
                meta : { requiresAuth: true },
                component: TaskNewFrSite
            },
            {
                path: 'taskEdit/:id',
                name: 'Edit Task',
                meta : { requiresAuth: true },
                component: TaskEdit
            },
            {
                path: 'taskEditContractor/:id',
                name: 'Edit Task Contractor',
                meta : { requiresAuth: true },
                component: TaskEditContractor
            },


            {
                path: 'importNew',
                name: 'New Import',
                meta : { requiresAuth: true },
                component: ImportNew
            },
            {
                path: 'importNewFrProject/:id',
                name: 'New Import For Project',
                meta : { requiresAuth: true },
                component: ImportNewFrProject
            },
            {
                path: 'importNewFrGroup/:id',
                name: 'New Import For Group',
                meta : { requiresAuth: true },
                component: ImportNewFrGroup
            },
            {
                path: 'importNewFrSite/:id',
                name: 'New Import For Site',
                meta : { requiresAuth: true },
                component: ImportNewFrSite
            },

            {
                path: 'projectDocumentNewFrProject/:id',
                name: 'New Project Document ',
                meta : { requiresAuth: true },
                component: ProjectDocumentNewFrProject
            },
            {
                path: 'projectDocumentEditFrProject/:id',
                name: 'New Project Document ',
                meta : { requiresAuth: true },
                component: ProjectDocumentEditFrProject
            },
            {
                path: 'projectDocumentDetailsListing/:id/:id2',
                name: 'Project Document Details Information',
                meta : { requiresAuth: true },
                component: ProjectDocumentDetailsListing
            },
            {
                path: 'projectDocumentRecurringListing/:id',
                name: 'Project Document Recurring Listing',
                meta : { requiresAuth: true },
                component: ProjectDocumentRecurringListing
            },
            {
                path: 'projectDocumentApprovalStatus/:id',
                name: 'Project Document Approval Status',
                meta : { requiresAuth: true },
                component: ProjectDocumentApprovalStatus
            },

            {
                path: 'projectDocumentListingAll/:id',
                name: 'All Project Document Listing',
                meta : { requiresAuth: true },
                component: ProjectDocumentListingAll
            },

            {
                path: 'projectDocumentListingAll/:id',
                name: 'All Project Document Listing',
                meta : { requiresAuth: true },
                component: ProjectDocumentListingAll
            },

            {
                path: 'siteDocumentListing',
                name: 'Site Document Listing',
                meta : { requiresAuth: true },
                component: SiteDocumentListing
            },
            {
                path: 'siteDocumentNew',
                name: 'New Site Document ',
                meta : { requiresAuth: true },
                component: SiteDocumentNew
            },
            {
                path: 'siteDocumentEdit/:id',
                name: 'Edit Site Document ',
                meta : { requiresAuth: true },
                component: SiteDocumentEdit
            },
            {
                path: 'siteDocumentDetailsListing/:id/:id2',
                name: 'Site Document Details Information',
                meta : { requiresAuth: true },
                component: SiteDocumentDetailsListing
            },

            {
                path: 'siteDocumentRecurringListing/:id',
                name: 'Site Document Recurring Listing',
                meta : { requiresAuth: true },
                component: SiteDocumentRecurringListing
            },
            {
                path: 'siteDocumentApprovalStatus/:id',
                name: 'Site Document Approval Status',
                meta : { requiresAuth: true },
                component: SiteDocumentApprovalStatus
            },
            {
                path: 'siteDocumentNewFrProject/:id',
                name: 'New Site Document ',
                meta : { requiresAuth: true },
                component: SiteDocumentNewFrProject
            },
            {
                path: 'siteDocumentNewFrGroup/:id',
                name: 'New Site Document ',
                meta : { requiresAuth: true },
                component: SiteDocumentNewFrGroup
            },
            {
                path: 'siteDocumentNewFrSite/:id',
                name: 'New Site Document ',
                meta : { requiresAuth: true },
                component: SiteDocumentNewFrSite
            },

            /****************************************************** */



            {
                path: 'documentListing',
                name: 'Document Listing',
                meta : { requiresAuth: true },
                component: DocumentListing
            },
            {
                path: 'documentNew',
                name: 'New Document',
                meta : { requiresAuth: true },
                component: DocumentNew
            },
            {
                path: 'documentEdit/:id',
                name: 'Edit Document',
                meta : { requiresAuth: true },
                component: DocumentEdit
            },
            {
                path: 'documentNewFrProject/:id',
                name: 'New Document From Project',
                meta : { requiresAuth: true },
                component: DocumentNewFrProject
            },
            {
                path: 'documentNewFrGroup/:id',
                name: 'New Document From Group',
                meta : { requiresAuth: true },
                component: DocumentNewFrGroup
            },
            {
                path: 'documentNewFrSite/:id',
                name: 'New Document From Site',
                meta : { requiresAuth: true },
                component: DocumentNewFrSite
            },

            {
                path: 'documentDetailsListing/:id',
                name: 'Document Details Listing',
                meta : { requiresAuth: true },
                component: DocumentDetailsListing
            },
            {
                path: 'documentDetailsNew/:id',
                name: 'Document Details New',
                meta : { requiresAuth: true },
                component: DocumentDetailsNew
            },

            {
                path: 'documentCommentListing/:id',
                name: 'Document Comment Listing',
                meta : { requiresAuth: true },
                component: DocumentCommentListing
            },
            {
                path: 'documentCommentNew/:id',
                name: 'New Document Comment',
                meta : { requiresAuth: true },
                component: DocumentCommentNew
            },
            {
                path: 'documentCommentEdit/:id',
                name: 'Edit Document Comment',
                meta : { requiresAuth: true },
                component: DocumentCommentEdit
            },



        ]
    },
    authPages,
];

export default routes;
